@import 'assets/scss/mixin.scss';

.faq {
    &__item {
        margin-bottom: 20px;
    }

    &__question {
        padding: 20px 10px;
        background: #FFF;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }

    &__answer {
        margin-top: 10px;
        padding-left: 20px;
        padding-right: 10px;
        @include adaptive-value('font-size', 16, 10, 3);
    }
}