@import 'assets/scss/mixin.scss';

.razdel {
    &__item {
        padding: 10px;
        background: #fff;

        &-img {
            img {
                object-fit: cover;
                @include adaptive-value('height', 300, 250, 3);
            }
        }

        &-date {
            display: flex;
            justify-content: space-between;
            opacity: 0.7;
            @include adaptive-value('font-size', 16, 10, 3);
        }

        &-text {
            @include adaptive-value('font-size', 12, 8, 3);
        }

        &-title {
            margin-bottom: 10px;
        }
    }
}