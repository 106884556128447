@import 'assets/scss/mixin.scss';

.docs {
    @include adaptive-value('margin-top', 60, 30, 3);

    &__title {
        @include adaptive-value('margin-bottom', 40, 20, 3);
    }

    &__item {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        background: $color1;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.35);
        border-radius: 5px;
        color: #FFF;
        padding: 20px;

        &-icon {

            svg {
                display: block;
                @include adaptive-value('width', 40, 30, 3);
                @include adaptive-value('height', 40, 30, 3);
            }
        }
    }
}