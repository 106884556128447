@import 'assets/scss/mixin.scss';

.reception {
    max-width: 700px;
    margin: 0 auto;

    &__input {
        border: 1px solid #777474;
        border-radius: 10px;
        margin-bottom: 10px;

        textarea,
        input {
            @include adaptive-value('padding', 20, 10, 3);
            width: 100%;
            outline: none;
            background: none;
            border: none;
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    &__file {
        display: none;
    }

    &__fileName {
        opacity: 0.7;
    }

    &__label {
        cursor: pointer;
    }

    &__text {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    &__btn {
        margin-top: 10px;
        cursor: pointer;
        background: $color2;
        color: #FFF;
        padding: 10px 32px;
        border-radius: 10px;
        @include adaptive-value('width', 175, 128, 3);
        @include adaptive-value('font-size', 22, 12, 3);

        svg {
            @include adaptive-value('width', 20, 15, 3);
            @include adaptive-value('height', 20, 15, 3);
            margin: 0 auto;

            circle {
                stroke: #FFF;
            }
        }
    }

    &__msg {
        background: #DEF0D8;
        padding: 10px;
        border-radius: 6px;
        margin-top: 10px;
        color: rgb(2, 193, 2);

        &.err {
            background: #F2DEDF;
            color: #FF4858;
        }
    }
}