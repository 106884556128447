@import 'assets/scss/mixin.scss';

.result {
    &__item {
        background: #FFF;
        display: flex;
        gap: 10px;
        margin-bottom: 30px;

        &-date {
            @include adaptive-value('font-size', 16, 10, 3);
            opacity: 0.7;
        }

        &-content {
            padding: 10px 0;
        }

        &-text {
            @include adaptive-value('font-size', 18, 9, 3);
        }

        &-title {
            line-height: 1.1;
            margin-bottom: 10px;
        }

        &-img {

            img {
                @include adaptive-value('width', 300, 130, 3);
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

@media (max-width:425px) {
    .result {
        &__item {
            display: block;
            padding: 10px;

            &-img {
                text-align: center;
            }

            &-content {
                padding: 0;
            }
        }
    }
}