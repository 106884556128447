@import 'assets/scss/mixin.scss';

.fullScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 1.0);
    padding: 0 10%;
    padding-top: 20px;

    &__close {
        position: absolute;
        right: 5%;
        top: 0px;
        display: block;
        z-index: 99;
        color: #FFF;
        font-size: 30px;
        cursor: pointer;
    }

    .swiperGallery {
        max-height: 80%;
        position: relative;

        .mySwiper img {
            @include adaptive-value('height', 180, 60, 3);
        }

        .mySwiper2 img {
            @include adaptive-value('height', 550, 230, 3);
        }
    }
}


@media (max-width: 768px) {

    .fullScreen {
        padding: 0 5%;
        @include adaptive-value('padding-top', 200, 150, 3);

        &__close {
            right: 1%;
            font-size: 20px;
            @include adaptive-value('top', 130, 120, 3);
        }
    }



}