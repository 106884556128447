@import 'assets/scss/mixin.scss';

.swiperGallery {

    .mySwiper2 {
        img {
            @include adaptive-value('height', 600, 250, 3);
            object-fit: contain;
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        color: $color1;
    }


    .mySwiper {
        .swiper-slide {
            cursor: pointer;
            opacity: 0.5;

            &-thumb-active {
                opacity: 1;
            }
        }

        img {
            @include adaptive-value('height', 225, 70, 3);
            object-fit: cover;
        }
    }
}