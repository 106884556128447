@import 'assets/scss/mixin.scss';

.newsItem {
    display: grid;
    grid-template-columns: 1.1fr 2fr;
    align-items: center;
    border-bottom: 2px solid $color1;
    @include adaptive-value('gap', 30, 10, 3);
    @include adaptive-value('padding-bottom', 30, 15, 3);
    @include adaptive-value('margin-bottom', 60, 30, 3);

    h3 {
        color: $color1;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        gap: 5px;
        @include adaptive-value('padding-top', 10, 0, 3);
        @include adaptive-value('padding-bottom', 10, 5, 3);
    }

    &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__img {
        @include adaptive-value('height', 200, 100, 3);

        img {
            height: 100%;
            box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
            object-fit: cover;
        }
    }

    &__more {
        a {
            color: $color1;
            text-decoration: underline;
        }
    }

    &__right {
        display: flex;
        gap: 15px;
    }

    &__views {
        display: flex;
        align-items: center;
    }

    &__text {
        @include adaptive-value('font-size', 14, 7, 3);
    }
}