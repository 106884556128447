@import 'assets/scss/mixin.scss';

.newsDoc {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 20px;
    margin-top: 20px;

    &.full {
        grid-template-columns: 1fr;
    }

    &__news {
        &__title {
            margin-bottom: 20px;
            display: flex;
            gap: 20px;
            align-items: center;

            h3 {
                a {
                    color: #5c5c5c;
                }

                a:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &__docs {
        margin-top: 10px;

        &__toraga {
            text-align: center;
            margin-bottom: 50px;

            h4 {
                color: $color1;
                margin-bottom: 10px;
            }

            &-img {
                margin-bottom: 10px;

                img {
                    @include adaptive-value('height', 400, 150, 3);
                    object-fit: cover;
                }
            }
        }

        &__item {
            display: block;
            background: #FFF;
            justify-content: space-between;
            font-weight: 600;
            box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
            overflow: hidden;
            @include adaptive-value('margin-top', 25, 10, 3);
            @include adaptive-value('padding', 20, 15, 3);
            padding-right: 0;

            &-content {
                width: 80%;
            }

            &-title {
                margin-bottom: 50px;
            }

            &-text {
                @include adaptive-value('font-size', 14, 10, 3);
                margin-bottom: 20px;
            }

            &-text,
            &-link {
                color: #5c5c5c;
            }

            &-link:hover {
                text-decoration: underline;
            }

            &-img {
                width: 20%;
                position: relative;
                height: 100%;
                right: -5%;
            }
        }
    }
}

@media(max-width:790px) {
    .newsDoc {
        grid-template-columns: 1fr;

        &__docs {
            &__items {
                display: grid;
                gap: 10px;
                grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
            }

            &__toraga {
                &-img {
                    img {
                        max-width: 300px;
                        @include adaptive-value('height', 400, 300, 3);
                    }
                }
            }
        }
    }
}