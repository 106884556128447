@import 'assets/scss/mixin.scss';

.urls {
    @include adaptive-value('margin-top', 60, 30, 3);

    .grid {
        align-items: flex-end;
    }

    &__title {
        @include adaptive-value('margin-bottom', 20, 10, 3);
    }

    &__item {
        &-url {
            border-bottom: 1px solid #ADACAC;
            padding-bottom: 5px;
            @include adaptive-value('margin-top', 15, 5, 3);
            display: grid;
            grid-template-columns: auto 20px;
            align-items: center;
            justify-content: space-between;
            position: relative;

            &-text {
                background: #343434;
                color: #FFF;
                position: absolute;
                top: -110%;
                right: 0;
                padding: 5px;
                border-radius: 3px;
                font-weight: 400;
            }

            a {
                word-break: break-all;
                font-weight: 600;
                color: $color2;
            }

            svg {
                cursor: pointer;
            }
        }
    }
}