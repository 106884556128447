@import 'assets/fonts/poppins/poppins.css';
@import 'assets/fonts/inter/stylesheet.css';
@import './assets/scss/mixin.scss';

.App {
    font-family: 'Inter', sans-serif;
    @include adaptive-value('font-size', 18, 12, 3);
}

button {
    font-weight: 600;
    @include adaptive-value('font-size', 18, 12, 3);
}

h1,
h2,
h3,
h4 {
    line-height: 1.3;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}

h2 {
    @include adaptive-value('font-size', 40, 20, 3);
}

h3 {
    @include adaptive-value('font-size', 22, 11, 3);
}

.container {
    max-width: 1280px;
    margin: 0 auto;
}

main {
    background: $color4;
    @include adaptive-value('padding-top', 50, 25, 3);
    @include adaptive-value('padding-bottom', 60, 30, 3);
}

a {
    text-decoration: none;
    color: #000;
}

@media(max-width:768px) {
    h3 {
        @include adaptive-value('font-size', 22, 11, 3);
    }
}

@media(max-width:1300px) {
    .container {
        @include adaptive-value('margin-left', 36, 18, 3);
        @include adaptive-value('margin-right', 36, 18, 3);
    }
}