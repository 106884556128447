@import 'assets/scss/mixin.scss';

.news {

    &__title {
        margin-bottom: 20px;
    }

    &__items {
        max-width: 900px;
    }
}