$color1: #2121EA;
$color2: #0C3F66;
$color3: #696969;
$color4: #F0F2F5;

$maxWidth: 1728;
$maxWidthContainer: 1280;



@mixin adaptive-indent($property, $TBstartSize, $TBminSize, $RLstartSize, $RLminSize, $type) {
    $TBaddSize: $TBstartSize - $TBminSize;
    $RLaddSize: $RLstartSize - $RLminSize;

    @if $type==1 {
        //Только если меньше контейнера
        #{$property}: $TBstartSize + px $RLstartSize + px;

        @media(max-width: #{$maxWidthContainer + px}) {
            #{$property}: calc(#{$TBminSize + px} + #{$TBaddSize} * ((100vw - 430px) / #{$maxWidthContainer - 430})) calc(#{$RLminSize + px} + #{$RLaddSize} * ((100vw - 430px) / #{$maxWidthContainer - 430}));
        }
    }

    @else if $type==2 {
        //Только если больше контейнера
        #{$property}: $TBstartSize + px;
        #{$property}: $RLstartSize + px;

        @media(min-width: #{$maxWidthContainer + px}) {
            #{$property}:calc(#{$TBminSize + px} + #{$TBaddSize} * ((100vw - 430px) / #{$maxWidth - 430})) calc(#{$RLminSize + px} + #{$RLaddSize} * ((100vw - 430px) / #{$maxWidth - 430}));
        }
    }

    @else {
        //Всегда
        #{$property}:calc(#{$TBminSize + px} + #{$TBaddSize} * ((100vw - 430px) / #{$maxWidth - 430})) calc(#{$RLminSize + px} + #{$RLaddSize} * ((100vw - 430px) / #{$maxWidth - 430}));
    }
}


@mixin adaptive-value($property, $startSize, $minSize, $type) {
    $addSize: $startSize - $minSize;

    @if $type==1 {
        //Только если меньше контейнера
        #{$property}: $startSize + px;

        @media(max-width: #{$maxWidthContainer + px}) {
            #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 430px) / #{$maxWidthContainer - 430}));
        }
    }

    @else if $type==2 {
        //Только если больше контейнера
        #{$property}: $startSize + px;

        @media(min-width: #{$maxWidthContainer + px}) {
            #{$property}:calc(#{$minSize + px} + #{$addSize} * ((100vw - 430px) / #{$maxWidth - 430}));
        }
    }

    @else {
        //Всегда
        #{$property}:calc(#{$minSize + px} + #{$addSize} * ((100vw - 430px) / #{$maxWidth - 430}));
    }
}