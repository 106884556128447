@import 'assets/scss/mixin.scss';

.documents {
    &__items {
        display: grid;
        grid-template-columns: 1fr 2fr 2fr 2fr 2fr 1fr;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        gap: 5px;
        border-bottom: 1px solid #B6B8BC;
        color: #959595;
        @include adaptive-value('font-size', 18, 9, 3);
        @include adaptive-value('padding-top', 10, 5, 3);
        @include adaptive-value('padding-bottom', 10, 5, 3);

        &.title {
            color: #000;
        }

        .name {
            color: #0000FF;
            @include adaptive-value('font-size', 14, 6, 3);

            &:hover {
                text-decoration: underline;
            }
        }
    }
}