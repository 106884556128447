@import 'assets/scss/mixin.scss';

.projects {
    @include adaptive-value('margin-top', 60, 30, 3);

    &__title {
        @include adaptive-value('margin-bottom', 30, 15, 3);
    }

    &__item {
        background: #FFF;
        font-weight: 600;
        box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
        @include adaptive-value('padding', 20, 15, 3);
        @include adaptive-value('padding-bottom', 40, 20, 3);

        &-date {
            color: $color1;
            @include adaptive-value('margin-bottom', 15, 5, 3);
        }
    }
}