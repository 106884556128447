@font-face {
    font-family: 'Inter';
    src: url('Inter-Regular.eot');
    src: local('Inter Regular'), local('Inter-Regular'),
        url('Inter-Regular.eot?#iefix') format('embedded-opentype'),
        url('Inter-Regular.woff2') format('woff2'),
        url('Inter-Regular.woff') format('woff'),
        url('Inter-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-Black.eot');
    src: local('Inter Black'), local('Inter-Black'),
        url('Inter-Black.eot?#iefix') format('embedded-opentype'),
        url('Inter-Black.woff2') format('woff2'),
        url('Inter-Black.woff') format('woff'),
        url('Inter-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-LightBETA.eot');
    src: local('Inter Light BETA'), local('Inter-LightBETA'),
        url('Inter-LightBETA.eot?#iefix') format('embedded-opentype'),
        url('Inter-LightBETA.woff2') format('woff2'),
        url('Inter-LightBETA.woff') format('woff'),
        url('Inter-LightBETA.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-SemiBold.eot');
    src: local('Inter Semi Bold'), local('Inter-SemiBold'),
        url('Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Inter-SemiBold.woff2') format('woff2'),
        url('Inter-SemiBold.woff') format('woff'),
        url('Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}


@font-face {
    font-family: 'Inter';
    src: url('Inter-Bold.eot');
    src: local('Inter Bold'), local('Inter-Bold'),
        url('Inter-Bold.eot?#iefix') format('embedded-opentype'),
        url('Inter-Bold.woff2') format('woff2'),
        url('Inter-Bold.woff') format('woff'),
        url('Inter-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}


@font-face {
    font-family: 'Inter';
    src: url('Inter-ExtraLightBETA.eot');
    src: local('Inter Extra Light BETA'), local('Inter-ExtraLightBETA'),
        url('Inter-ExtraLightBETA.eot?#iefix') format('embedded-opentype'),
        url('Inter-ExtraLightBETA.woff2') format('woff2'),
        url('Inter-ExtraLightBETA.woff') format('woff'),
        url('Inter-ExtraLightBETA.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-ExtraBold.eot');
    src: local('Inter Extra Bold'), local('Inter-ExtraBold'),
        url('Inter-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Inter-ExtraBold.woff2') format('woff2'),
        url('Inter-ExtraBold.woff') format('woff'),
        url('Inter-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-ThinBETA.eot');
    src: local('Inter Thin BETA'), local('Inter-ThinBETA'),
        url('Inter-ThinBETA.eot?#iefix') format('embedded-opentype'),
        url('Inter-ThinBETA.woff2') format('woff2'),
        url('Inter-ThinBETA.woff') format('woff'),
        url('Inter-ThinBETA.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-Medium.eot');
    src: local('Inter Medium'), local('Inter-Medium'),
        url('Inter-Medium.eot?#iefix') format('embedded-opentype'),
        url('Inter-Medium.woff2') format('woff2'),
        url('Inter-Medium.woff') format('woff'),
        url('Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}