@import 'assets/scss/mixin.scss';

.newsMore {

    &__date {
        display: flex;
        align-items: center;
        justify-content: space-between;
        opacity: 0.7;
        margin-bottom: 10px;
    }

    &__text {
        margin-top: 20px;
    }

    .mySwiper2 {

        img {
            @include adaptive-value('height', 600, 250, 3);
            object-fit: contain;
        }
    }

    .mySwiper {
        .swiper-slide {
            opacity: 0.5;

            &-thumb-active {
                opacity: 1;
            }
        }

        img {
            @include adaptive-value('height', 225, 70, 3);
            object-fit: contain;
        }
    }
}