@import 'assets/scss/mixin.scss';

.page {
    &__text {
        margin: 30px 0;
    }

    &__photo {
        cursor: pointer;
        object-fit: cover;
        height: 250px;
    }
}