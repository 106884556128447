@import 'assets/scss/mixin.scss';

.personal {
    &__item {
        background: #FFF;
        text-align: center;

        &-img {
            img {
                object-fit: cover;
                @include adaptive-value('height', 350, 300, 3);
            }
        }

        &-content {
            padding: 10px 0;
        }

        &-job {
            margin-top: 10px;
            color: $color2;
        }
    }
}