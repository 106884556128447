@import 'assets/scss/mixin.scss';

.loader {
    @include adaptive-value('width', 200, 100, 3);
    @include adaptive-value('height', 200, 100, 3);
    margin: 50px auto;
    display: block;

    circle {
        stroke: #696969;
    }
}