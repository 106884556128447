@import 'assets/scss/mixin.scss';

body.bvi-active {
    .topHeader__right-glasses {
        display: none;
    }
}

.topHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFF;
    column-gap: 10px;
    border-bottom: 1px solid rgba(175, 175, 175, 0.6);

    &__left {
        @include adaptive-value('padding-top', 32, 16, 3);
        @include adaptive-value('padding-bottom', 32, 16, 3);

        &-logo {
            display: grid;
            grid-template-columns: auto 4fr;
            gap: 10px;
            @include adaptive-value('gap', 20, 5, 3);
            align-items: center;

            img {
                @include adaptive-value('width', 100, 75, 3);
            }
        }

        &-burger {
            display: none;

            .menu {
                display: block;
            }

            .cross {
                display: none;
            }
        }

        &-title {
            @include adaptive-value('font-size', 18, 6, 3);
        }
    }

    &__right {
        color: $color3;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include adaptive-value('gap', 30, 15, 3);

        &-lang {
            display: flex;
            gap: 15px;

            h3 {
                cursor: pointer;
            }

            .active {
                color: $color1;
            }
        }

        &-glasses {
            cursor: pointer;
            color: $color1;
        }

        &-icons {
            display: flex;
            align-items: center;
            @include adaptive-value('gap', 30, 10, 3);

            a {
                color: $color1;
            }
        }

        &-date {
            color: $color1;
            white-space: nowrap;
        }

        svg {
            width: 24px;
            height: 24px;
            @include adaptive-value('width', 24, 16, 3);
            @include adaptive-value('height', 24, 16, 3);
        }
    }
}


@media(max-width:768px) {
    .topHeader {
        display: block;
        border: none;

        &__left {
            border-bottom: 1px solid rgba(175, 175, 175, 0.6);
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include adaptive-value('gap', 20, 5, 3);

            &-title {
                @include adaptive-value('font-size', 18, 10, 3);
            }

            &-burger {
                display: block;

                svg {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        &__right {
            display: block;

            &-lang {
                justify-content: center;
                @include adaptive-value('margin-top', 60, 20, 3);
                @include adaptive-value('margin-bottom', 60, 20, 3);
            }

            &-icons {
                @include adaptive-value('margin-bottom', 50, 15, 3);
                justify-content: space-between;
            }
        }

        &.active {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            margin: 0;
            z-index: 99;
            @include adaptive-value('padding-left', 100, 18, 3);
            @include adaptive-value('padding-right', 100, 18, 3);

            .topHeader {
                &__right {
                    display: none;
                }

                &__left {
                    border: none;



                    .menu {
                        display: none;
                    }

                    .cross {
                        display: block;
                    }

                }
            }
        }
    }
}