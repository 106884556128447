@import 'assets/scss/mixin.scss';

.siteMap {

    &__item {
        margin-bottom: 30px;

        @include adaptive-value('margin-left', 30, 20, 3);
        @include adaptive-value('font-size', 30, 16, 3);

        a {
            color: blue;
        }

        a:hover {
            text-decoration: underline;
        }

        ul li {
            padding-left: 0;
            @include adaptive-value('font-size', 20, 13, 3);
            margin-left: 20px;
            margin-top: 10px;
        }
    }
}