@import 'assets/scss/mixin.scss';

.banner2 {
    background: #D2D4D8;

    .container {
        padding: 20px 0;
        @include adaptive-value('column-gap', 50, -15, 3);
        @include adaptive-value('row-gap', 50, 20, 3);
    }

    &__item {
        text-align: center;

        img {
            @include adaptive-value('height', 250, 150, 3);
            object-fit: cover;
        }
    }
}

@media (max-width:600px) {
    .banner2 {

        .container {
            grid-template-columns: 1fr;
        }

        &__item {}
    }
}