.razdelMore {
    &__date {
        display: flex;
        align-items: center;
        justify-content: space-between;
        opacity: 0.7;
        margin-bottom: 10px;
    }

    &__text {
        margin-top: 50px;
    }
}