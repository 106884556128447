@import 'assets/scss/mixin.scss';

.navBackground {
    background: $color1;
}

.nav {
    display: flex;
    justify-content: space-between;
    transition: all 0.3s ease;
    align-items: center;
    text-align: center;
    font-weight: 600;
    column-gap: 10px;
    color: #DFDDDD;
    @include adaptive-value('padding-top', 20, 10, 3);
    @include adaptive-value('padding-bottom', 20, 10, 3);

    a,
    button {
        color: #DFDDDD;
    }

    .active {
        color: #FFF;
        text-decoration: underline;
    }

    &__razdel {
        text-align: left;
        position: relative;

        &-first {
            display: flex;
            margin: 0 auto;
            align-items: center;
            cursor: pointer;

            svg {
                width: 18px;
                height: 18px;

                polyline {
                    stroke: #DFDDDD;
                }
            }
        }

        &-subCategory {
            position: relative;

            &-first {
                display: flex;
                align-items: center;
                cursor: pointer;

                svg {
                    width: 18px;
                    height: 18px;
                }

                &:hover {
                    color: #FFF;
                }
            }

            &-more {
                background: $color1;
                position: absolute;
                left: 100%;
                top: 0;
                @include adaptive-value('font-size', 16, 12, 3);

                a {
                    padding: 3px;
                    display: block;
                }
            }
        }

        &-more {
            position: absolute;
            display: none;
            background: $color1;
            padding: 10px;
            padding-right: 0;
            left: -10px;
            gap: 10px;
        }




        &.show {
            color: #DFDDDD;

            polyline {
                stroke: #FFF;
            }

            .nav__razdel {
                &-first {
                    color: #FFF;
                }

                &-more {
                    display: grid;
                    z-index: 9;

                    a:hover {
                        text-decoration: underline;
                        color: #FFF;
                    }

                }
            }
        }
    }
}


@media(max-width:768px) {
    .nav {
        position: fixed;
        display: grid;
        justify-content: center;
        @include adaptive-value('gap', 20, 10, 3);
        background: #F0F2F5;
        width: 100%;
        min-height: 100%;
        top: -100%;
        left: 0;
        color: #000;

        &.active {
            margin: 0;
            padding-top: 150px;
            z-index: 90;
            top: 0;
            transition: all 0.6s ease;

            a,
            button {
                color: #000;
            }
        }



        &.container {
            margin: 0;
        }

        &__razdel {
            text-align: center;

            &-first {
                svg {
                    polyline {
                        stroke: #000;
                    }
                }
            }

            &-subCategory {
                &-more {
                    background: none;
                    position: static;
                    display: grid;
                    grid-template-columns: auto auto auto auto;
                    justify-content: space-between;
                }

                &-first {
                    margin-bottom: 5px;

                    &:hover {
                        color: #000;
                        text-decoration: underline;
                    }
                }
            }

            &.show {
                color: #000;

                svg {
                    polyline {
                        stroke: #000;
                    }
                }

                .nav__razdel {
                    &-first {
                        color: #000;
                    }

                    &-more {
                        display: grid;
                        z-index: 9;

                        a:hover {
                            text-decoration: underline;
                            color: #000;
                        }

                    }
                }
            }

            &-more {
                position: static;
                background: none;
            }
        }
    }
}