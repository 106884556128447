* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input,
button {
  background: none;
  border: none;
}

img {
  width: 100%;
}

.bvi-open {
  position: absolute;
  top: -100%;
  right: 0;

  svg {
    width: 1.5em;
    height: 1.5em;
  }
}