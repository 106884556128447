@import 'assets/scss/mixin.scss';

.search {
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.35);
    border-radius: 20px;
    display: flex;
    gap: 5px;
    align-items: center;
    margin: 0 auto;
    @include adaptive-value('width', 250, 200, 3);


    &__btn {
        width: auto;
        padding: 5px;
        border-radius: 20px 0 0 20px;
        cursor: pointer;
    }

    &__input {
        height: 100%;
        outline: none;
        width: 100%;
        padding: 5px;
        padding-right: 0;
        margin-right: 10px;
    }
}

@media (max-width:768px) {
    .search {
        @include adaptive-value('margin-bottom', 60, 20, 3);
    }
}