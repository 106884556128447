@import 'assets/scss/mixin.scss';

.personalMore {

    &__top {
        display: grid;
        grid-template-columns: 1fr 2fr;
        @include adaptive-value('gap', 50, 30, 3);
    }

    &__img {
        img {
            @include adaptive-value('height', 400, 300, 3);
            object-fit: contain;
        }
    }

    &__title {
        div {
            margin-top: 10px;
        }
    }
}


@media(max-width:768px) {
    .personalMore {
        &__top {
            grid-template-columns: 1fr;
        }

        &__img {
            text-align: center;

            img {

                width: 50%;
                height: auto;
            }
        }
    }
}