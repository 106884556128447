@import 'assets/scss/mixin.scss';

.documentsMore {

    iframe {
        height: auto;
        @include adaptive-value('height', 800, 450, 3);
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__date {
        display: flex;
        gap: 20px;
        align-items: center;
    }

    &__btn {
        display: block;
        position: relative;
        margin-right: 10px;

        svg {
            cursor: pointer;
            width: 1.5em;
            height: 1.5em;
        }
    }

    &__copy {
        background: #343434;
        color: #FFF;
        position: absolute;
        top: -130%;
        right: 0;
        padding: 5px;
        border-radius: 3px;
        font-weight: 400;
    }

    &__views {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    &__bottom {
        margin-top: 30px;
    }

    &__download {
        background: $color1;
        border-radius: 6px;
        padding: 10px 20px;
        color: #FFF;
    }
}

@media (max-width:700px) {
    .documentsMore {
        &__date {
            display: block;

            div {
                margin-bottom: 10px;
            }
        }
    }
}