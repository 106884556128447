.pathname {
    color: #696969;
    margin-bottom: 20px;

    a:hover {
        text-decoration: underline;
    }

    &__active {
        display: inline;
        text-decoration: underline;
    }
}