@import 'assets/scss/mixin.scss';

.layout {
    h1 {
        @include adaptive-value('margin-bottom', 60, 30, 3);
    }

    &__pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        @include adaptive-value('margin-top', 60, 30, 3);

        &-item {
            cursor: pointer;

            &.active {
                pointer-events: none;
                background: #FFF;
                border-radius: 100%;
                padding: 3px 7px;
            }
        }

        &-prev,
        &-next,
        &-first,
        &-last {
            cursor: pointer;
        }

        .disable {
            opacity: 0.6;
            pointer-events: none;
        }
    }
}