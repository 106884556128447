@import 'assets/scss/mixin.scss';

.footer {
    @include adaptive-value('padding-bottom', 70, 40, 3);
    background: $color1;
    color: #FFF;

    .topHeader {
        background: none;

        &__left {


            &-burger {
                display: none;
            }
        }

        &__right {
            color: #FFF;

            &-icons {

                a,
                button {
                    color: #FFF;
                }
            }

            &-date {
                color: #FFF;
            }

            &-lang {
                color: #8D8D8D;

                .active {
                    color: #FFF;
                }
            }
        }
    }

    .nav {
        display: none;
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        @include adaptive-value('gap', 125, 30, 3);
        @include adaptive-value('margin-top', 60, 30, 3);

        #custom-script-container {
            margin-top: 10px;

            img {
                width: auto;
            }
        }

        &__text {
            color: #FFF;
            font-weight: 400;
            margin-top: 10px;
            display: block;
        }

        a:hover {
            text-decoration: underline;
        }
    }
}

@media(max-width:768px) {
    .footer {
        .topHeader {

            &__left {
                &-burger {
                    display: none;
                }
            }
        }

        &__bottom {
            display: grid;
        }
    }
}